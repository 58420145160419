/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2022-04-13 10:12:43
 * @modify date 2022-04-13 10:12:43
 * @desc [description]
 */

import jss from 'jss';
import { customAlphabet } from 'nanoid';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import { parsePath, StatehookClass, StatehookName } from '$/statehook/typescript';
import { createElement } from '$/libraries/html_element_creator';
import { promiseSleep } from '$/libraries/promise-sleep';

const themeService = require('$/services/theme_service');

const handGif = require('$/assets/images/animated_hand.gif');
const finishGif = require('$/assets/images/finish.gif');

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 32);

const styleSheet = jss.createStyleSheet({
  hamburgerIconClassname: {
    left: 'calc(100% - 310px)!important',
    top: 'calc(100% - 290px)!important',
  },
  robotStatusIconClassname: {
    transform: 'translateY(-155px) translateX(50px)',
  },
  title: {
    color: 'rgb(235, 87, 235)',
  },
  description: {
    color: 'var(--theme-color)',
    margin: '25px 0px 25px 0px',
  },
  next: {
    'margin-left': '5px',
  },
  skip: {
    'font-size': '0.8rem',
  },
});
export class TutorialDriverServiceClass extends StatehookClass {
  previousFunctionName: string
  nextFunctionName: string
  skipFunctionName: string
  nextSteps: any[] = []
  prevSteps: any[] = []
  currStep: () => {}
  private driver: Driver
  private sessionService: any
  static override grabOne(statehookName: StatehookName) {
    return super.grabOne([].concat(['TutorialDriverService'], parsePath(statehookName, '/')));
  }
  get currStepParameter(): any {
    return this.statehookGetPath('currStepParameter');
  }
  set currStepParameter(newValue: any) {
    this.statehookSetPath('currStepParameter', newValue);
  }
  get isTutorialOn(): boolean {
    return Boolean(this.currStep);
  }
  protected _onInit(args: any) {
    if (!styleSheet.attached) {
      styleSheet.attach();
    }
    const { sessionService } = args;
    this.sessionService = sessionService;

    const nextFunctionName = '_' + nanoid();
    (window as any)[nextFunctionName] = () => {
      this.next();
    };
    const previousFunctionName = '_' + nanoid();
    (window as any)[previousFunctionName] = () => {
      this.prev();
    };
    const skipFunctionName = '_' + nanoid();
    (window as any)[skipFunctionName] = () => {
      this.skip();
    };
    this.nextFunctionName = nextFunctionName;
    this.previousFunctionName = previousFunctionName;
    this.skipFunctionName = skipFunctionName;
    // steps
    this.nextSteps = [() => {
      // this.doStepHamburgerIcon();
      this.doStepProfile();
    // }, () => {
    //   this.doStepInvite();
    }, () => {
      this.doStepJoycon();
    }, () => {
      this.doStepBluetooth();
    }, () => {
      this.doStepBlocklyTaskIcon();
    }, () => {
      this.doStepClock();
    // }, () => {
    //   this.doStepRobotStatus();
    // }, () => {
    //   this.doStepRobotStatusTooltip();
    }, () => {
      this.doStepUnityTaskIcon();
    // }, () => {
    //   this.doStepUnityTaskWindow();
    }, () => {
      this.doStepTutorialsIcon();
    // }, () => {
    //   this.doStepTutorialsWindow();
    }, () => {
      this.doStepHamburgerIcon();
    // }, () => {
    //   this.doStepHamburgerMenu();
    }, () => {
      this.doStepFinish();
    }];
    // steps END
    this.statehookRegisterUnwatch([
      () => {
        if (styleSheet.attached) {
          styleSheet.detach();
        }
      },
      this.statehookWatchPath('currStepParameter', () => {
        if (this.currStepParameter) {
          this.driver.highlight(Object.assign(
            {},
            this.currStepParameter,
            {
              stageBackground: 'var(--theme-bg)',
            },
          ));
        } else {
          // reset
          this.nextSteps = [].concat(this.prevSteps, [this.currStep].filter(Boolean), this.nextSteps);
          this.prevSteps = [];
          this.currStep = null;
          this.driver.reset();
        }
        //
      }),
      () => {
        (window as any)[nextFunctionName] = undefined;
        (window as any)[previousFunctionName] = undefined;
        (window as any)[skipFunctionName] = undefined;
      },
    ]);
  }
  initDriverIfNeeded() {
    if (!this.driver) {
      const driver = new Driver({
        allowClose: false,
        opacity: 0.6,
        // onHighlightStarted: (element: any) => {
        //   console.log(element, 'xxx');
        // },
        onReset: () => {
          if (this.currStepParameter) this.currStepParameter = null;
        },
      });
      this.driver = driver;
    }
  }

  doStepProfile() {
    this.sessionService.userProfileWindowService.show();
    if (!this.sessionService.blocklyWindowService.isWindowMinimised) {
      this.sessionService.blocklyWindowService.minimise();
    }
    if (!this.sessionService.webglWindowService.isWindowMinimised) {
      this.sessionService.webglWindowService.minimise();
    }
    setTimeout(() => {
      const helloElement: any = createElement(`
        <div
          style="
            background-color: rgb(235, 87, 235);
            color: white;
            position: absolute;
            top: 30px;
            left: 30px;
            z-index: 100010!important;
            border-radius: 10px;
            width: 400px;
            height: 270px;
            padding: 15px;
          "
        >
          <div class="row align-items-center">
            <div class="col">
              <img src="${handGif}" />
            </div>
            <div class="col">
              <span style="font-size: 3rem;">Welcome!</span>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col" style="font-size: 1.6rem">
              Kainundrum is a code &#39;n play app for kids, students, parents & teachers.
            </div>
          </div>
        </div>
      `);
      document.body.appendChild(helloElement);
      this.currStepParameter = {
        element: '[data-web-window-key="user_profile_window"]',
        popover: {
          // className: 'theme-bg-glass',
          title: `<div class="${styleSheet.classes.title}">Your player profile</div>`,
          description: `<div class="${styleSheet.classes.description}">Customize your emoji, player color and choose a hat for your robot. Your emoji + hat becomes your player name.</div>
          <div class="d-flex justify-content-between">
            <div>
              <button onclick="${this.skipFunctionName}()" class="btn btn-secondary">Cancel</button>
              <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}" style="margin-left: 10px">Next</button>
            </div>
            <div>
              <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
            </div>
          </div>`,
          position: 'right-bottom',
          showButtons: false,
        },
        onDeselected: () => {
          document.body.removeChild(helloElement);
          try {
            this.sessionService.userProfileWindowService.close();
          } catch (error) {
            //
          }
        },
      };
    }, 0.5 * 1e3);
  }
  // doStepInvite() { // Currently not in use as multiplayer is deprecated
  //   this.sessionService.playerListWindowService.open();
  //   this.currStepParameter = {
  //     element: '[data-driver-element-name="invite_icon"]',
  //     popover: {
  //       // className: 'theme-bg-glass',
  //       title: `<div class="${styleSheet.classes.title}">Multiplayer</div>`,
  //       description: `<div class="${styleSheet.classes.description}">Invite your friends to play along with you.</div>
  //       <div class="d-flex justify-content-between">
  //         <div>
  //           <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
  //           <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
  //         </div>
  //         <div>
  //           <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
  //         </div>
  //       </div>`,
  //       showButtons: false,
  //     },
  //     onDeselected: () => {
  //       this.sessionService.playerListWindowService.close();
  //     },
  //   };
  // }
  doStepBluetooth() {
    this.currStepParameter = {
      element: '[data-driver-element-name="robot_indicator_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Bluetooth Pairing</div>`,
        description: `<div class="${styleSheet.classes.description}">If you have a physical KaiBot robot, this is where you pair it.</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
    };
  }
  doStepJoycon() {
    const { gamepadService } = this.sessionService;
    if (gamepadService.isMinimised) {
      gamepadService.recover();
    }
    this.currStepParameter = {
      element: '[data-driver-element-name="joystick_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Joystick or keyboard</div>`,
        description: `<div class="${styleSheet.classes.description}">Use WASD keys to control your robot</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
      onDeselected: () => {
        if (!gamepadService.isMinimised) {
          gamepadService.minimiseToCentre();
        }
      },
    };
  }
  async doStepBlocklyTaskIcon() {
    if (!this.sessionService.blocklyWindowService.isWindowNormal) { // open
      this.sessionService.blocklyWindowService.minimise();
    }
    await promiseSleep(0.3 * 1e3);
    this.currStepParameter = {
      element: '[data-driver-element-name="blockly_task_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Code view</div>`,
        description: `<div class="${styleSheet.classes.description}">Show or hide your coding window</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
      onDeselected: () => {
        if (!this.sessionService.blocklyWindowService.isWindowMinimised) { // minimise
          this.sessionService.blocklyWindowService.minimise();
        }
      },
    };
  }
  doStepBlocklyTaskWindow() {
    this.currStepParameter = {
      element: '[data-web-window-key="blockly_window"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Code view</div>`,
        description: `<div class="${styleSheet.classes.description}">Show or hide the coding window.</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        position: 'right',
        showButtons: false,
      },
    };
  }
  doStepClock() {
    this.currStepParameter = {
      element: '[data-driver-element-name="clock_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Start / Stop + Timer</div>`,
        description: `<div class="${styleSheet.classes.description}">Start & stop the game as well as time races</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
    };
  }
  // async doStepRobotStatus() {
  //   this.currStepParameter = {
  //     element: '[data-driver-element-name="robot_status_switch_icon"]',
  //     popover: {
  //       className: styleSheet.classes.robotStatusIconClassname,
  //       title: `<div class="${styleSheet.classes.title}">Virtual robot modes</div>`,
  //       description: `<div class="${styleSheet.classes.description}">Try out each of these modes on your robot. With code you can sense objects, colors and more!</div>
  //       <div class="d-flex justify-content-between">
  //         <div>
  //           <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
  //           <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
  //         </div>
  //         <div>
  //           <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
  //         </div>
  //       </div>`,
  //       position: 'right',
  //       showButtons: false,
  //     },
  //     onDeselected: () => {
  //       this.sessionService.uiService.robotStatusSwitchTooltipVisibility = false;
  //     },
  //   };
  //   await promiseSleep(0.3 * 1e3);
  //   this.sessionService.uiService.robotStatusSwitchTooltipVisibility = true;
  // }
  async doStepRobotStatusTooltip() {
    this.sessionService.uiService.robotStatusSwitchTooltipVisibility = true;
    await promiseSleep(0.3 * 1e3);
    this.currStepParameter = {
      element: '[data-driver-element-name="robot_status_tooltip"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Virtual robot modes</div>`,
        description: `<div class="${styleSheet.classes.description}">Try out each of these modes on your robot. With code you can sense objects, colors and more!</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
    };
    await promiseSleep(0.3 * 1e3);
    this.sessionService.uiService.robotStatusSwitchTooltipVisibility = true;
  }
  doStepUnityTaskIcon() {
    if (!this.sessionService.webglWindowService.isWindowNormal) { // open
      this.sessionService.webglWindowService.minimise();
    }
    this.currStepParameter = {
      element: '[data-driver-element-name="unity_task_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">3D virtual view</div>`,
        description: `<div class="${styleSheet.classes.description}">Use code or WASD keys to control your robot. Check out the game editor to create your own games</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
      onDeselected: () => {
        if (!this.sessionService.webglWindowService.isWindowMinimised) { // minimise
          this.sessionService.webglWindowService.minimise();
        }
      },
    };
  }
  doStepUnityTaskWindow() {
    this.currStepParameter = {
      element: '[data-web-window-key="webgl_window"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">3D virtual view</div>`,
        description: `<div class="${styleSheet.classes.description}">Use code or WASD keys to control your robot. Check out the game editor to create your own games</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        position: 'left',
        showButtons: false,
      },
    };
  }

  async doStepTutorialsIcon() {
    this.sessionService.tutorialVideoService.openVideoWindow();
    await promiseSleep(0.5 * 1e3);
    this.currStepParameter = {
      element: '[data-driver-element-name="help_icon"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Tutorials</div>`,
        description: `<div class="${styleSheet.classes.description}">Want the upper edge on your team mates? Check out the user guide, videos & learn how to create your own games</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
      onDeselected: () => {
        this.sessionService.tutorialVideoService.closeVideoWindow();
      },
    };
  }
  async doStepTutorialsWindow() {
    this.sessionService.tutorialVideoService.openVideoWindow();
    await promiseSleep(0.5 * 1e3);
    this.currStepParameter = {
      element: '[data-web-window-key="tutorial_video_window"]',
      popover: {
        // className: 'theme-bg-glass',
        title: `<div class="${styleSheet.classes.title}">Tutorials</div>`,
        description: `<div class="${styleSheet.classes.description}">Want the upper edge on your team mates? Check out the user guide, videos & learn how to create your own games</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        showButtons: false,
      },
    };
  }
  async doStepHamburgerIcon() {
    this.sessionService.uiService.hamburgerMenuVisible = true;
    // await promiseSleep(0.3 * 1e3);
    this.currStepParameter = {
      element: '.data-driver-element-hamburger_icon',
      popover: {
        className: styleSheet.classes.hamburgerIconClassname,
        title: `<div class="${styleSheet.classes.title}">Hamburger menu</div>`,
        description: `<div class="${styleSheet.classes.description}">Hungry to learn more? Check out these snacks!</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        // position: 'top-left',
        showButtons: false,
      },
      onDeselected: () => {
        this.sessionService.uiService.hamburgerMenuVisible = false;
      },
    };
  }
  async doStepHamburgerMenu() {
    this.sessionService.uiService.hamburgerMenuVisible = true;
    await promiseSleep(0.3 * 1e3);
    this.currStepParameter = {
      element: '.settings-menu-tooltip',
      popover: {
        // className: styleSheet.classes.hamburgerIconClassname,
        title: `<div class="${styleSheet.classes.title}">Hamburger menu</div>`,
        description: `<div class="${styleSheet.classes.description}">Hungry to learn more? Check out these snacks!</div>
        <div class="d-flex justify-content-between">
          <div>
            <button onclick="${this.previousFunctionName}()" class="btn btn-secondary">Previous</button>
            <button onclick="${this.nextFunctionName}()" class="btn btn-primary ${styleSheet.classes.next}">Next</button>
          </div>
          <div>
            <button onclick="${this.skipFunctionName}()" class="btn btn-link ${styleSheet.classes.skip}">Skip tutorial</button>
          </div>
        </div>`,
        position: 'left',
        showButtons: false,
      },
    };
  }

  async doStepFinish() {
    this.currStepParameter = null;
    await alertLib.sweetAlert({
      confirmButtonText: 'Explore',
      html: `
        <div class="row align-items-center">
          <div class="col">
            <img src="${finishGif}" />
          </div>
          <div class="col">
            <span style="font-size: 2.5rem;" class="${styleSheet.classes.title}">Well done!</span>
          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col">
            <span style="font-size: 1.7rem;">
              You&#39;re now ready to explore Kainundrum!
            </span>
          </div>
        </div>
      `,
    });
    if (this.sessionService.blocklyWindowService.isWindowMinimised) {
      this.sessionService.blocklyWindowService.minimise();
    }
    if (this.sessionService.webglWindowService.isWindowMinimised) {
      this.sessionService.webglWindowService.minimise();
    }
    this.sessionService.sendCommunityData('ui', 'unity', 'ui_onboarding_finished');
  }
  skip() {
    this.initDriverIfNeeded();
    if (this.currStep) this.prevSteps.push(this.currStep);
    this.currStep = null; // important
    this.currStepParameter = null;
    
    this.sessionService.receiveCommunityData('unity', '*', 'unity_state_ready', () => {
      console.log("tutorial skipped, telling Unity");
      this.sessionService.sendCommunityData('ui', 'unity', 'ui_onboarding_finished');
    });
  }
  prev() {
    this.initDriverIfNeeded();
    if (this.currStep) this.nextSteps.unshift(this.currStep);
    const currStep = this.prevSteps.pop();
    if (currStep) {
      this.currStep = currStep;
      currStep();
    } else {
      this.currStep = null; // important
      this.currStepParameter = null;
    }
  }
  next() {
    this.initDriverIfNeeded();
    if (this.currStep) this.prevSteps.push(this.currStep);
    const currStep = this.nextSteps.shift();
    if (currStep) {
      this.currStep = currStep;
      currStep();
    } else {
      this.currStep = null; // important
      this.currStepParameter = null;
    }
  }
}
